import React from "react";
import Navbar from "../components/Navbar/Navbarone";
import { Link } from 'react-router-dom'

const Page404 = () => {
  return (
    <div>
      <Navbar />
      <div className="mt-16 pt-16 mb-12 flex flex-col md:ml-20 ml-0 justify-center w-[90%] items-center py-12">
      <h1 className="text-[#3267FF] text-3xl font-bold">Page Not Found.</h1>
    <p>404</p>
     <Link to="/">
     <button class="mt-auto rounded-md bg-[#3267FF] font-[Poppins]  p-4 w-44   text-[18px] text-white text-center">
        <p>Home</p>
     </button>
 </Link>
      </div>
    </div>
  );
};

export default Page404;
