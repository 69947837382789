// src/components/TermsPage.js

import React from 'react'
import { Navbar } from '../components'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Policy = () => {
  return (
    <>
      <HelmetProvider>
       <Helmet>
       <title>{`${"Privacy Policy - OPDSure"}`}</title>
       <meta name="description" content="Know more about Privacy and Policy of Opdsure.com" />
       <link rel="canonical" href="https://opdsure.com/privacy" />


<meta name="twitter:title" content={`${"Privacy Policy - OPDSure"}`}/>
<meta name="twitter:description" content="Know more about Privacy and Policy of Opdsure.com"/>
<meta name="twitter:url" content="https://opdsure.com/privacy" />

<meta property="og:title" content={`${"Privacy Policy - OPDSure"}`}/>
<meta property="og:url" content="https://opdsure.com/privacy" />
<meta property="og:description" content="Know more about Privacy and Policy of Opdsure.com"/>

    </Helmet>
    </HelmetProvider>
      <Navbar />
      <div className="bg-gray-100 min-h-screen  flex items-center justify-center">
        <div className="bg-white p-8 shadow-lg rounded-lg w-[70%] h-screen overflow-y-auto my-32 mx-4 sm:mx-auto">
          <iframe
            src="https://opdsure.s3.ap-south-1.amazonaws.com/privacy_policy.html"
            className="min-h-screen w-full"
          ></iframe>
        </div>
      </div>
    </>
  )
}

export default Policy
