import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Home} from "./screens";

import { Footer} from './components';
import ContactUs from "./components/ContactUs";
import Thankyou from "./components/Thankyou";
import Plans from "./screens/Plans";
import OurServices from "./screens/OurServices";
import { logo } from './assets'
import About from "./screens/About";
import Carrier from "./screens/Career";
import Payment from "./screens/Payment";
import TermsPage from "./screens/TermPage";
import Page404 from "./screens/PageNotFound";
import Policy from "./screens/PrivacyPolicy";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { herobg,herobgsm } from "./assets";
import TagManager from 'react-gtm-module'
import Blog from "./screens/Blogs";
import BlogPage from "./screens/BlogPage";
const tagManagerArgs = {
  gtmId: 'GTM-PPFH7LZR'
}
TagManager.initialize(tagManagerArgs);

function App() {
  window.dataLayer.push({
    event: 'pageview'
  });

  // if(navigator.userAgentData.mobile){
    let d =   new Image().src = herobgsm;
  // }
  // else{
    // let d =   new Image().src = herobg;
  // }

  return (
    <div className="App">
      <HelmetProvider>
       <Helmet>
       <meta name="twitter:image" content={logo} />
       <meta name="og:image" content={logo} />
    </Helmet>
    </HelmetProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/careers" element={<Carrier />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/payment" element={<Payment />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/signup" element={<SignUp />} /> */}
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<Policy />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-detail" element={<BlogPage />} />
        <Route path="*" element={<Page404 />} />
        {/* <Route path="/otp-verify" element={<OtpScreen />} /> */}
        {/* <Route path="/otptwilio" element={<OtpVerificationForm />} /> */}
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
