import React,{useState,useEffect} from "react";
import Navbar from "../components/Navbar/Navbarone";
import { Contact } from "../components";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import './blogs.css';

const Blog = () => {
  
 
  const [blogs, setBlogs] = useState([]); // Update state to hold an array directly

  useEffect(() => {
    axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://opdsure.wordpress.com/feed/")
      .then((res) => { 
        // console.log(res.data.items);
        setBlogs(res.data.items); // Update state with fetched blog items
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{`${"Blog at OPDSure"}`}</title>
          <meta name="description" content="Everyone at OPDSure (Finlyt Solutions Pvt. Ltd.) is free to explore and work the way you want. Come join us!" />
          <link rel="canonical" href="https://opdsure.com/blog" />

          <meta name="twitter:title" content={`${"Blog at OPDSure"}`} />
          <meta name="twitter:description" content="Everyone at OPDSure (Finlyt Solutions Pvt. Ltd.) is free to explore and work the way you want. Come join us!" />
          <meta name="twitter:url" content="https://opdsure.com/blog" />

          <meta property="og:title" content={`${"Blog at OPDSure"}`} />
          <meta property="og:url" content="https://opdsure.com/blog" />
          <meta property="og:description" content="Everyone at OPDSure (Finlyt Solutions Pvt. Ltd.) is free to explore and work the way you want. Come join us!" />

        </Helmet>
      </HelmetProvider>
      <Navbar />

      <div className="mb-12 mt-12 flex items-center justify-center flex-col">
        <div className="flex items-center justify-center flex-col section">
          <div className="">
            {/* <h2 className="text-[--primary-color] text-lg rounded-md font-[700]  border-2  p-2">
                Our Services
              </h2> */}
          </div>
          <div className="my-2">
            <h3 className="text-[#000A2D] text-[50px] font-[800]">Our Blog</h3>
          </div>
          {/* <div className=" my-2 md:w-[40%] w-[90%]">
            <p className="text-center ">
              Our blog section.
            </p>
          </div> */}
        </div>
        <div className="flex items-center justify-center flex-wrap">
          <div className="container md:w-[80%] w-[90%]">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
             
              {blogs.map((item, index) => {
                return (
                  <div style={{boxShadow:"rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px"}}
                    key={index}
                    className="md:rounded-r-lg flex md:flex-row flex-col-reverse w-full justify-between shadow-md mb-4 bg-[#F4F6FA] h-full rounded-lg hover:shadow-lg transition-transform duration-300 transform"
                  >
                     
                    <div className="justify-between ">
                    <Link to={"/blog-detail"+"/?title="+item.title.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g,' ').replaceAll(" ",'-').replaceAll("--",'-')+"&uid="+item.guid}>
                   
                      

                      <img
                        src={item.thumbnail}
                        // alt={item.title}
                        className="md:h-[18rem] justify-between h-[12rem] img-r-t md:mt-0 mt-4 object-cover"
                      />
                       <h4 className="text-[#333] mt-4 mb-1 p-3 md:text-1xl text-lg font-[700]">
                    {item.title.length > 44 ?
    `${item.title.substring(0, 44)}..` : item.title  }
    
                        </h4>
                        <div>
                        <p className="text-[#333333] md:text-sm p-3 text-xs font-[400]">
                          {item.description.length > 200 ?
    `${item.description.substring(0, 200)}..` : item.description}
                        </p>
                      </div>
                      </Link>
                      <div>
                      
                        
                        <Link to={"/blog-detail"+"/?title="+item.title.replaceAll(" ",'-')+"&uid="+item.guid}
                      className={`bg-blue-500 hover:bg-blue-700 ml-3 mb-3 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline`}
                     >
                      Read More <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-[#3267FF] ml-2 h-4 w-4 mt-4 rounded-full bg-white"
                          />
                    </Link>
                      </div>
                    </div>

                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Blog;
