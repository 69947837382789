import React from "react";
import CarrierHero from "../components/Career/CarrierHero";
import Navbar from "../components/Navbar/Navbarone";
import { Contact } from "../components";
import CarrierValue from "../components/Career/CarrierValue";
import CarrierOpening from "../components/Career/CarrierOpening";
import CareerService from "../components/Career/CarrierService";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Carrier = () => {
  return (
    <div>
       <HelmetProvider>
       <Helmet>
       <title>{`${"Careers at OPDSure"}`}</title>
       <meta name="description" content="Everyone at OPDSure (Finlyt Solutions Pvt. Ltd.) is free to explore and work the way you want. Come join us!" />
       <link rel="canonical" href="https://opdsure.com/careers" />

<meta name="twitter:title" content={`${"Careers at OPDSure"}`} />
<meta name="twitter:description" content="Everyone at OPDSure (Finlyt Solutions Pvt. Ltd.) is free to explore and work the way you want. Come join us!"/>
<meta name="twitter:url" content="https://opdsure.com/careers" />

<meta property="og:title" content={`${"Careers at OPDSure"}`}/>
<meta property="og:url" content="https://opdsure.com/careers" />
<meta property="og:description" content="Everyone at OPDSure (Finlyt Solutions Pvt. Ltd.) is free to explore and work the way you want. Come join us!"/>

    </Helmet>
    </HelmetProvider>
      <Navbar />
      <CarrierHero />
      <CarrierValue/>
      <CareerService/>
      <CarrierOpening/>
      <div className=" md:mt-20 mt-4">
      {/* <h1 className="text-[#3267FF] text-3xl font-bold md:ml-64 ml-8">Any other queries</h1> */}
        <Contact />
      </div>
    </div>
  );
};

export default Carrier;
