// src/components/TermsPage.js

import React from "react";
import { Navbar } from "../components";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const TermsPage = () => {
  return (
    <div>
        <HelmetProvider>
       <Helmet>
       <title>{`${"Terms and Conditions - OPDSure"}`}</title>
       
       <meta name="description" content="Know more about Terms and Conditions of Opdsure.com" />
       <link rel="canonical" href="https://opdsure.com/terms" />


       <meta name="twitter:title" content={`${"Terms and Conditions - OPDSure"}`}/>
<meta name="twitter:description" content="Know more about Terms and Conditions of Opdsure.com"/>
<meta name="twitter:url" content="https://opdsure.com/terms" />

<meta property="og:title" content={`${"Terms and Conditions - OPDSure"}`}/>
<meta property="og:url" content="https://opdsure.com/terms" />
<meta property="og:description" content="Know more about Terms and Conditions of Opdsure.com"/>

    </Helmet>
    </HelmetProvider>
    <Navbar/>
    <div className="bg-gray-100 min-h-screen  flex items-center justify-center">
      <div className="bg-white p-8 shadow-lg rounded-lg w-[90%] min-h-screen my-32 mx-4 sm:mx-auto">
       
        <iframe src="https://opdsure.s3.ap-south-1.amazonaws.com/terms-and-conditions.html" className="min-h-screen w-full"></iframe>
      </div>
    </div>
    </div>
  );
};

export default TermsPage;
