import React from 'react'
import NavbarOne from '../components/Navbar/Navbarone'
import Testimonial from './../components/Testimonial';

import { Contact, Faq, Subscription } from '../components';
import PlanHero from '../components/PlanHero';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Plans = () => {
  return (
    <div>
      <HelmetProvider>
       <Helmet>
       <title>{`${"Best OPD Expense Cover Plan for Individual & Family | Corporate OPD Plans"}`}</title>
       <meta name="description" content="Looking for the best opd expense cover plan for individual or family? Buy health plan for family or individual from OPDSure. You can also buy Corporate OPD Plans for your employees." />
       <link rel="canonical" href="https://opdsure.com/plans" />


       
<meta name="twitter:title" content={`${"Best OPD Expense Cover Plan for Individual & Family | Corporate OPD Plans"}`}/>
<meta name="twitter:description" content="Looking for the best opd expense cover plan for individual or family? Buy health plan for family or individual from OPDSure. You can also buy Corporate OPD Plans for your employees."/>
<meta name="twitter:url" content="https://opdsure.com/plans" />

<meta property="og:title" content={`${"Best OPD Expense Cover Plan for Individual & Family | Corporate OPD Plans"}`}/>
<meta property="og:url" content="https://opdsure.com/plans" />
<meta property="og:description" content="Looking for the best opd expense cover plan for individual or family? Buy health plan for family or individual from OPDSure. You can also buy Corporate OPD Plans for your employees."/>

    </Helmet>
    </HelmetProvider>
        <NavbarOne/>
        <PlanHero/>
        <Subscription/>
        <Testimonial visible={true}/>
        {/* <Faq/> */}
        <Contact/>
    </div>
  )
}

export default Plans
