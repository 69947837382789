import React from "react";
import { aboutus,aboutus_sm, checkIcon } from "../assets";
import { Link } from "react-router-dom";
import scrollToTop from "./scrollToTop";

const About = () => {
  const feature = [
    {
      name: "Unlimited Doctor Consultations",
    },
    {
      name: "Consult Doctors 24*7",
    },
    {
      name: "Claim Pharmacy & Lab Test Bills",
    },
    {
      name: "Easy Claim Process",
    },
  ];
  return (
    <div className="h-full">
      <div className=" flex justify-center items-center">
        <div className="grid grid-cols-1 md:grid-cols-2  items-center justify-center">
          <div className="flex items-center justify-center">
            <img
              src={aboutus}
              srcSet={`${aboutus_sm} 420w , ${aboutus} 1000w`}
              alt="About us"
              className="md:h-[650px] h-[450px]  z-10"
            />
          </div>
          <div className="p-12">
            <div className="flex items-center md:justify-start justify-center">
              <h2 className="text-[--primary-color] text-lg rounded-md font-[700]  border-2 p-2">
                About OPDSure
              </h2>
            </div>
            <div>
              <h3 className="md:text-5xl text-2xl font-[600] mt-8 mb-4" style={{'lineHeight':1.3}}>
                Simplifying your <br /> Health Journey
              </h3>
            </div>
            <div className="w-[100%] md:w-[80%]  text-justify">
              OPDSure simplifies and revolutionizes healthcare access by making
              it more user-centric and approachable. Our comprehensive plans lay
              emphasis on OPD expenses borne by our customers. Nearly 75% of an
              individual’s lifetime medical costs are, on average, incurred
              outside of hospitals/clinics. OPDSure is driven by the vision
              that doctor visits, pharmacy costs, diagnostic/lab tests and
              annual health check-ups, don't dry up your savings. Thereby, we
              have crafted annual OPD healthcare plans that completely take care
              of everyone across India, thus covering Individual coverage and
              also providing for employees in a Corporate.
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 w-[90%] gap-6 my-8">
              {feature.map((item, index) => {
                return (
                  <div className="flex flex-row" key={index}>
                    <img src={checkIcon} alt={item.name} className="h-8" />
                    &nbsp;
                    <span className="text-[#000A2D] text-lg font-[700]">
                      {item.name}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-wrap md:gap-10 gap-4  items-center justify-start">

              <Link to="/about" onClick={scrollToTop}>
              <button
                className="mt-auto rounded-md bg-[#3267FF] font-[700]  p-4 md:w-44  w-32  md:text-[18px] text-[14px] text-white text-center"
                
              >
                Learn More
              </button>
              </Link>
              <Link to="/contactus" onClick={scrollToTop}>
                <button className="mt-auto rounded-md text-[#3267FF] font-[700]  p-4 md:w-36 w-30   md:text-[18px] text-[14px] border-[#98B3FF3B] border-[2px]  bg-white text-center">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
