import React from 'react'
import NavbarOne from '../components/Navbar/Navbarone'
import HeroAbout from '../components/AboutUs/HeroAbout'
import { Contact } from '../components'
import InfoAbout from '../components/AboutUs/InfoAbout'
import AboutCompany from '../components/AboutUs/AboutCompany'
import MeetTeam from '../components/AboutUs/MeetTeam'
import AboutValue from '../components/AboutUs/AboutValue'
import CarrierValue from '../components/Career/CarrierValue'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const About = () => {
  return (
    <div>
         <HelmetProvider>
       <Helmet>
       <title>{`${"About Us - OPDSure"}`}</title>
       <meta name="description" content="OPDSure is a leading healthcare provider in India. Buy Health Care Plans Online in India for your family & employees to save the opd expenses." />
       <link rel="canonical" href="https://opdsure.com/about" />

       <meta name="twitter:title" content={`${"About Us - OPDSure"}`}/>
<meta name="twitter:description" content="OPDSure is a leading healthcare provider in India. Buy Health Care Plans Online in India for your family & employees to save the opd expenses."/>
<meta name="twitter:url" content="https://opdsure.com/about" />

<meta property="og:title" content={`${"About Us - OPDSure"}`}/>
<meta property="og:url" content="https://opdsure.com/about" />
<meta property="og:description" content="OPDSure is a leading healthcare provider in India. Buy Health Care Plans Online in India for your family & employees to save the opd expenses."/>

    </Helmet>
    </HelmetProvider>
        <NavbarOne/>
        <HeroAbout/>
        <InfoAbout/>
        <AboutCompany/>
        <MeetTeam/>
        <CarrierValue/>
        {/* <AboutValue/> */}
        <Contact/>
    </div>
  )
}

export default About
