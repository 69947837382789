import React from "react";
import { Link } from "react-router-dom";
import scrollToTop from "../scrollToTop";
import { checkIcon } from "../../assets";
const SubscriptionCard = ({
  plan,
  description,
  features,
  icon,
  notRequired,
  color,
  price,
  onclick,
  btnText = "Start Trial",
}) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className="flex min-h-[450px] w-[320px] flex-col justify-between rounded-3xl p-4 text-center shadow-lg"
    >
      <div>
        <div className="flex items-center justify-center">
          <img src={icon} alt={plan} className="bg-[--primary-main] rounded-full p-2" />
        </div>
        <h4 className="mb-1 text-[18px] font-medium">{plan}</h4>
        <div className="mb-3 flex items-center justify-center text-center text-3xl font-[600]">
          <>
            <div>{price}/month</div>
          </>
        </div>
        <div className="mb-3 text-[1.2rem] font-[500] text-[#404040]">
          {description}
        </div>
   <div className="flex justify-start flex-col">
    <span className="text-start text-[#404040] font-[600] my-4">Benefits you’ll get:</span>
      <ul className="mb-10 flex flex-col gap-y-2">
        {features.map((feature, i) => (
        <li
        key={i}
        className="flex items-start text-left text-[15px] text-[#404040] font-[400]"
      >
        <img
          src={checkIcon}
          alt={feature}
          className="w-5 h-5 mr-2 text-green-500"
        />
        <span className="leading-[1.3]">{feature}</span>
      </li>
      
        ))}
        {/* {notRequired.map((feature, index) => (
          <li key={index} className="flex items-center text-[18px] font-bold">
            ❌&nbsp;{feature}
          </li>
        ))} */}
      </ul>
      </div>
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={onclick}
          className="mt-auto rounded-md bg-[#3267FF]  p-3 w-36   text-[18px] text-white text-center"
        >
          <Link to="https://dashboard.opdsure.com/auth/sign-up" onClick={scrollToTop}>
            {btnText}
          </Link>
        </button>
      </div>
    </div>
  );
};

export default SubscriptionCard;
