import React,{useState,useEffect,useSearchParams} from "react";
import Navbar from "../components/Navbar/Navbarone";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import ReactDOM from 'react-dom/client';

const Blog = () => { 
 
  const [blogs, setBlogs] = useState([]); 
  const [blog, setBlog] = useState([]);
  const searchParams = new URLSearchParams(document.location.search)

  useEffect(() => {
    axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://opdsure.wordpress.com/feed/")
      .then((res) => { 
        console.log(res.data.items);
        let d = res.data.items;
        setBlogs(d.filter(item => item.guid === searchParams.get('uid')));
        try{
          
        setBlog(d.filter(item => item.guid === searchParams.get('uid'))[0]);
        }
        catch(e){}
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{`${blog.title}`}</title>
          <meta name="description" content={`${blog.description}`} />
          <link rel="canonical" href="https://opdsure.com/blog" />
          <meta name="twitter:title" content={`${blog.title}`} />
          <meta name="twitter:description" content={`${blog.description}`} />
          <meta name="twitter:url" content="https://opdsure.com/blog" />
          <meta property="og:title" content={`${blog.title}`} />
          <meta property="og:url" content="https://opdsure.com/blog" />
          <meta property="og:description" content={`${blog.description}`} />
        </Helmet>
      </HelmetProvider>
      <Navbar />

      <div className="mb-12 mt-12 flex items-center justify-center flex-col">
        {/* <div className="flex items-center justify-center flex-col section">
          <div className="">
           
          </div>
          <div className="my-2">
            <h3 className="text-[#000A2D] text-[50px] font-[800]">Blog</h3>
          </div>
          <div className=" my-2 md:w-[40%] w-[90%]">
            <p className="text-center ">
              Our blog section.
            </p>
          </div>
        </div> */}
        <div className="flex items-center justify-center flex-wrap">
          <div className="container md:w-[70%] w-[90%]">
            <div className="grid grid-cols-1 gap-8 mt-10" id="blog-detail">
              
              {blogs.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex md:flex-row flex-col-reverse w-full justify-between 
                    shadow-md bg-[#F4F6FA] h-full rounded-lg hover:shadow-lg 
                    transition-transform duration-300 transform">

                    <div className="justify-between p-4">
                    
                        <h3 className="mt-10 text-[#000A2D] text-[30px] mb-10 font-[800]"> {item.title}</h3>
                        <img 
                        src={item.thumbnail}
                        className="justify-between md:rounded-r-lg rounded-lg md:mt-0 mb-4 mt-4 object-cover"
                      />
                      <div className="mb-5" id="blog">
                        
                        <div dangerouslySetInnerHTML={{ __html: item.content }}>
                       
                        </div>
                      </div>
                  
                    </div>

                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Blog;
