import React from "react";

const HeroAbout = () => {
  return (
    <div className="pt-24 h-full  flex items-start  flex-col justify-between">
      <div className="md:w-[80%] w-[95%] md:pl-40 pl-4 mt-12">
        {/* <div className=" flex items-center justify-start  my-4">
          <h1 className="text-[--primary-color] text-lg rounded-md font-[700]  border-2  p-2">
             About Us
          </h1>
        </div> */}
        <div className="my-4">
          <h1 className="md:text-5xl text-3xl  text-left  leading-snug font-[600]"  style={{'lineHeight':1.3}}>
            Revolutionizing OPD Care  to <br className="md:flex hidden" />{" "}
            Ensure Healthier and <br />
            <span className="text-[#3267FF]" style={{lineHeight: 1.4}}>Empowered Community</span>
          </h1>
        </div>
        <div className="my-4">
          <p className="text-justify font-[400] text-sm">
            At OPDSure, we are sincerely committed to delivering affordable &
            reliable healthcare OPD annual subscription plans to everyone across
            India. Our goal is to fill in the gaps in existing medical
            assistance in a more approachable manner with the help of
            technology. OPDSure brings you pocket-friendly, four different
            annual subscription plans that cover a wide range of benefits i.e.
            Unlimited doctor consultation, reimbursement on pharmacy bills &
            diagnostic tests and free health checkups. Our sole intent is to
            provide a seamless customer experience & economical medical aid all
            in one place. We are one of a kind in the health sector catering to
            the needs of individuals and corporates.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroAbout;
