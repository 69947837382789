import React from "react";

import {
  Countdown,
  Faq,
  Hero,
  Subscription,
  About,
  Service,
  Contact,
  Navbar,
} from "../components";
import Services from "../components/Services/Services";
import Testimonial from "../components/Testimonial";
import WellnessPlan from "../components/WellnessPlan";
import Table from "../components/Table";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {logo} from '../assets/index';
const Home = () => {
  return (
    <div>
       <HelmetProvider>
       <Helmet>
       <title>{`${"Best Healthcare Company | Health Care Provider in India - OPDSure"}`}</title>
       <meta name="description" content="Are you looking for a best healthcare company in India that can provide you the best OPD Expense cover plans at best price? Contact OPD Sure that is a best health care provider in India." />
       <link rel="canonical" href="https://opdsure.com" />

       <meta property="og:image" content={`${logo}`} />
       <meta name="twitter:image" content="https://opdsure.com/static/media/logo.862c904ca4b3e8e9948a.png" />



    </Helmet>
    </HelmetProvider>
      <Navbar/>
      <Hero />
      <Countdown />
      <About />
      <Services/>
      <WellnessPlan/>
      <Testimonial visible={true}/>
      <Subscription />
      <Table/>
      <Service />
      <Faq />
      <Contact />
    </div>
  );
};

export default Home;
