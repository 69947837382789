import React from 'react'
import HeroService from '../components/HeroService'
import NavbarOne from '../components/Navbar/Navbarone'
import { Contact } from '../components'
import ServiceCardOne from './../components/ServiceCardOne'
import ServiceCardTwo from '../components/ServiceCardTwo'
import ServiceCardThree from '../components/ServiceCardThree'
import ServiceCardFour from '../components/serviceCardfour'
import ServiceBanner from '../components/Servicebanner'
import ServiceCycle from '../components/Servicecycle'
import CorporateBanner from '../components/CorporateBanner'
import CorporateBannerTwo from '../components/CorporateBannerTwo'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const OurServices = () => {
  return (
    <div>
         <HelmetProvider>
       <Helmet>
       <title>{`${"Patient Care Service Provider in India - OPDSure"}`}</title>
       <meta name="description" content="OPDSure is the leading patient care service provider in India who provides best opd expense saving plans. They also provides digital health solutions to individual, family and employees." />
       <link rel="canonical" href="https://opdsure.com/services" />


<meta name="twitter:title" content={`${"Patient Care Service Provider in India - OPDSure"}`}/>
<meta name="twitter:description" content="OPDSure is the leading patient care service provider in India who provides best opd expense saving plans. They also provides digital health solutions to individual, family and employees."/>
<meta name="twitter:url" content="https://opdsure.com/services" />

<meta property="og:title" content={`${"Patient Care Service Provider in India - OPDSure"}`}/>
<meta property="og:url" content="https://opdsure.com/services" />
<meta property="og:description" content="OPDSure is the leading patient care service provider in India who provides best opd expense saving plans. They also provides digital health solutions to individual, family and employees."/>

    </Helmet>
    </HelmetProvider>
      <NavbarOne />
      <HeroService />
      <ServiceCycle />
      <ServiceBanner />
      <ServiceCardOne />
      <ServiceCardFour />
      <ServiceCardThree />
      <ServiceCardTwo />
      <div id="corporatexx">
        <CorporateBannerTwo />
      </div>
      <Contact visible={true} />
    </div>
  )
}

export default OurServices
