import React, { useState } from "react";

import { servicehero } from "../assets";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";

const HeroService = () => {
  const opts = {
    height: "100%", // Full height
    width: "100%", // Full width
    playerVars: {
      autoplay: 1,
      controls: 0,
      loop: 1,
    },
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const openModal1 = () => {
    setIsOpen1(true);
  };
  const openModal2 = () => {
    setIsOpen2(true);
  };
  const openModal3 = () => {
    setIsOpen3(true);
  };
  const closeModal = () => {
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const serviceLinks = {
    apolloMedicine:
      "https://www.apollopharmacy.in/?utm_source=HANA_Brand&utm_medium=Brand&utm_campaign=OPDSure_Pharma",
    apolloLabTest:
      "https://www.apollo247.com/lab-tests?utm_source=HANA_Brand&utm_medium=Brand&utm_campaign=OPDSure_Labtest",
    insurance:
      "https://www.smcinsurance.com/Myaccount/Account/LoginLink?Token=efa0ad34df8447fd8eb9b52f2cadb8aa",
    pharmaEasy:
      "https://pharmeasy.in/online-medicine-order?utm_source=alz-finlyt&utm_medium=alliance&utm_campaign=finlyt-20april2022",
  };
  return (
    <div className="py-24 h-full  flex md:items-start items-center  md:flex-row flex-col md:justify-between justify-center">
      <div className="md:w-[80%] w-[100%] md:pl-40 px-4 my-12">
        <div className="flex items-center justify-start  my-4">
          <h1 className="text-[--primary-color] rounded-md font-[700]  border-2  p-2">
            Our Services
          </h1>
        </div>
        <div className="my-8">
          <h2 className="md:text-5xl text-3xl  leading-snug font-[600]">
            OPDSure - India’s leading <br className="md:flex hidden" />{" "}
            <span className="text-[#3267FF]">One Stop Shop</span> for OPD Care
          </h2>
        </div>
        <div className="gap-10 grid grid-cols-2 md:grid-cols-3">
          <button
            onClick={openModal1}
            className="mt-auto  rounded-md text-[#3267FF] font-[700]  p-3 border border-solid border-blue-700 p-4  text-[18px] bg-white text-center"
          >
            Order Medicine
          </button>

          {isOpen1 && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal">
                <div className="modal-header">
                  <h2>Order Medicine</h2>
                  <button onClick={closeModal}>Close</button>
                </div>
                <div className="modal-content">
                  <Link to={serviceLinks.pharmaEasy} target="new">
                    <button className="mt-auto rounded-md bg-[#3267FF] font-[700]  p-4 md:w-44  w-32  md:text-[18px] text-[14px] text-white text-center">
                      PharmaEasy
                    </button>
                  </Link>
                  <Link to={serviceLinks.apolloMedicine} target="new">
                    <button className="mt-auto rounded-md bg-[#3267FF] font-[700]  p-4 md:w-44  w-32  md:text-[18px] text-[14px] text-white text-center">
                      Apollo 24/7
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}

          <a
            // onClick={openModal2}
            href={serviceLinks.apolloLabTest}
            target="new"
            className="mt-auto rounded-md text-[#3267FF] font-[700]  p-3 border border-solid border-blue-700 p-4 text-[18px] bg-white text-center"
          >
            Order Lab Test
          </a>

          {/* {isOpen2 && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal">
                <div className="modal-header">
                  <h2> Order Lab Test</h2>
                  <button onClick={closeModal}>Close</button>
                </div>
                <div className="modal-content">
                  <button className="mt-auto rounded-md bg-[#3267FF] font-[700]  p-4 md:w-44  w-32  md:text-[18px] text-[14px] text-white text-center">
                    Apollo 24/7
                  </button>
                </div>
              </div>
            </div>
          )} */}

          <a
            // onClick={openModal3}
            href={serviceLinks.insurance}
            target="new"
            className="mt-auto rounded-md text-[#3267FF] font-[700]  p-3 border border-solid border-blue-700 p-4  text-[18px] bg-white text-center"
          >
            Buy Insurance
          </a>

          {/* {isOpen3 && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal">
                <div className="modal-header">
                  <h2>Insurance</h2>
                  <button onClick={closeModal}>Close</button>
                </div>
                <div className="modal-content">
                  <button className="mt-auto rounded-md bg-[#3267FF] font-[700]  p-4 md:w-44  w-32  md:text-[18px] text-[14px] text-white text-center">
                    Insurance
                  </button>
                </div>
              </div>
            </div>
          )} */}
        </div>
        <div className="my-4">
          {/* <p className="text-justify font-[400] text-lg">
            OPDSure Services ensures your smooth journey and covers you towards
            Health and Wellness
          </p> */}
        </div>
      </div>
      <div className="flex item-center justify-center md:w-[70%]">
        {/* <YouTube
          videoId={"J1VZXgp_ac8"}
          opts={opts}
          className="md:mt-8 md:h-[400px] md:w-[600px] h-auto rounded-lg"
        /> */}
        <img
          src={servicehero} // Use the imported image source here
          alt="Hero Image"
          // Add responsive styling
        />
      </div>
    </div>
  );
};

export default HeroService;
