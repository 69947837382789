import React from 'react'
import { service1, service2, service3, service4, service1sm, service2sm, service3sm, service4sm } from '../../assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Services = () => {
  const service = [
    {
      title: 'Specialist Doctor Consultations',
      description:
        'Access top specialist doctors anytime and anywhere in India, whether through online or in-person consultations.',
      link: '',
      image: service2,
      imagesm: service2sm
    },
    {
      title: 'Buy Prescribed Medications Anytime ',
      description:
        'OPDSure offers you reliable, customer-centric and cost-effective ways to buy medicines through our technology while uploading your prescription easily in just a few clicks.Our partners will deliver your pharmacy needs in no time.',
      link: '',
      image: service4,
      imagesm: service4sm
    },
    {
      title: 'Book Lab Tests Near You',
      description:
        'We aim to empower our customers, with essential knowledge about various medical lab tests and diagnostic services available in your area. You may avail this service at home - hassle free.',
      link: '',
      image: service3,
      imagesm: service3sm
    },
    {
      title: 'Claim Settlement',
      description:
        'OPDSure believes in on-time reimbursement model, thus, all reimbursements are processed in less than 2 working days to ensure a seamless claim settlement process',
      link: '',
      image: service1,
      imagesm: service1sm
    },
  ]
  return (
    <div className="mb-12 flex items-center justify-center flex-col">
      <div className="flex items-center justify-center flex-col section">
        <div className="">
          <h2 className="text-[--primary-color] text-lg rounded-md font-[700]  border-2  p-2">
            Our Services
          </h2>
        </div>
        <div className="my-2">
          <h3 className="text-[#000A2D] text-[50px] font-[800]">Services</h3>
        </div>
        <div className=" my-2 md:w-[40%] w-[90%]">
          <p className="text-center ">
            Our services are Annual Subscription based healthcare plans, crafted
            in a way that all your health and wellness needs are catered at one
            place.
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center flex-wrap">
        <div className="container md:w-[80%] w-[90%]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {service.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex md:flex-row flex-col-reverse w-full justify-between shadow-md bg-[#F4F6FA] h-full rounded-lg hover:shadow-lg transition-transform duration-300 transform hover:-translate-y-2"
                >
                  <div className="flex flex-col justify-between p-4 md:w-[60%] w-[90%]">
                    <div>
                      <h4 className="text-[#333] md:text-2xl text-lg font-[700]">
                        {item.title}
                      </h4>
                    </div>
                    <div>
                      <p className="text-[#333333] md:text-sm text-xs font-[400]">
                        {item.description}
                      </p>
                    </div>
                    <div>
                      <a
                        href="https://dashboard.opdsure.com/"
                        target="_blank"
                        className="inline-block mt-2 text-[#3267FF] flex items-center font-bold text-lg transition-transform duration-300 transform hover:translate-x-2"
                      >
                        Learn more
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="text-[#3267FF] ml-2 h-4 w-4 p-2 rounded-full bg-white"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="flex items-end justify-center">
                    <img
                      src={item.image}
                      srcSet={`${item.imagesm} 420w , ${item.image} 1000w`}
                      alt={item.title}
                      className="md:h-[18rem] md:w-[16rem] h-[12rem] md:rounded-r-lg rounded-lg md:mt-0 mt-4 object-cover"
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
