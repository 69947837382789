import React from "react";
import { drawline, herobg,herobgsm,group1, group2, group3, group4 } from "../assets";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import scrollToTop from "./scrollToTop";

const Hero = () => {
  const group = [
    {
      name: "Doctor Consultations",
      image: group2,
      color: "#FFDCEFB2",
    },
    {
      name: "Claim Pharmacy",
      image: group1,
      color: "#E5DCFFB2",
    },
    {
      name: "Claim Lab Test",
      image: group3,
      color: "#FFEED8B2",
    },
    {
      name: "Quick Claim Process",
      image: group4,
      color: "#E5DCFFB2",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://quiet-stump-cut.glitch.me/medpay-authorization"
        );
        // const data = await response.json();
        console.log(response.data); // Update the state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className=" h-full   md:pt-24 pt-16 w-full bg-[--main-color] flex flex-col items-center justify-between">
      <div className="flex items-center md:flex-row flex-col justify-center container m-auto">
        <div className="flex flex-col md:ml-20 ml-0 justify-center w-[90%] md:w-[50%] py-12">
          <div className="flex  flex-col md:items-start items-center justify-center">
            {/* <div className="flex  item-center md:justify-start  justify-center">
              <p className="text-[16px] border-2 p-2 border-gray-300 rounded-lg  font-[700] text-[#000000]">
                👋 Hey! We Are OPDSure
              </p>
            </div> */}
            <h1 className="  text-[#000A2D] py-4 text-2xl  leading-snug   text-center md:text-left  md:text-6xl font-[600]">
            Your Partner for a Healthy Tomorrow{" "}
              {/* <img
                src={drawline}
                className="h-10  relative md:top-[-3.5rem] top-[-2.5rem]  md:left-[50%] left-[85%]"
                alt=""
              /> */}
            </h1>
          </div>

          <p className="text-[16px] text-justify text-[#636571] font-[400] md:my-0 my-2  md:w-[70%] w-[100%]">
            OPDSure offers comprehensive and flexible OPD plans for
            employees and individuals. These subscription-based plans cater to
            the health needs of every age group and promote healthy living.
          </p>

          <div className="grid grid-cols-4 gap-2 mt-4 mb-8 w-[100%] md:w-[60%]">
            {group.map((item, index) => {
              return (
                <div
                  className="flex flex-col md:items-center items-start  justify-start py-4 "
                  key={index}
                >
                  <div
                    className={`p-4 rounded-lg mb-2`}
                    style={{ backgroundColor: `${item.color}` }}
                  >
                    <img src={item.image} alt={item.name} className="h-10" />
                  </div>
                  <h2 className="text-[#4C4C4C] md:text-[12px] text-[10px] font-[500] text-center">
                    {item.name}
                  </h2>
                </div>
              );
            })}
          </div>
          <div className="flex md:items-start items-center md:justify-start justify-center ">
            <Link to="/plans" onClick={scrollToTop}>
              <button className="mt-auto rounded-md bg-[#3267FF] font-[Poppins]  p-4 w-44   text-[18px] text-white text-center">
                Check Prices
              </button>
            </Link>
          </div>
        </div>

        <div>
          
          <img
            fetchpriority="high"
            srcSet={`${herobgsm} 420w, ${herobg} 1000w`}
            src={herobg}
            alt="banner image"
            className=" h-[380px] md:h-[600px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
