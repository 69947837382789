import React, { useState } from "react";
import { stratoscope } from "../assets";
import NavbarOne from './Navbar/Navbarone';
import Contact from "./Contact";

const Thankyou = () => {
  return (
    <>
    <NavbarOne/>
    <div className="md:pt-20 pt-10 ">
    <div>
      <div
        className="bg-white  flex flex-wrap items-center justify-center my-20"
        id="contact"
      >
        <div className="bg-[#3267FF] h-full p-12 flex flex-col  rounded-tl-md rounded-bl-md">
          <div>
            <h1 className="text-3xl text-white font-[800]">
              Thankyou!
            </h1>
            <h2 className="text-[#C9C9C9] my-4">
            We have received your message. <br/>One of our representatives will get in touch with you shortly.
            </h2>
          </div>
        </div>
      </div>
    </div> 
    </div>
    </>
  );
};

export default Thankyou;
