import React from "react";
import { servicebg, servicewoman,servicewoman_sm } from "../../assets";
import "./Service.css";
import { Link } from "react-router-dom";
const Service = () => {
  return (
    <div className="flex items-center justify-between bg-[#F1F8FF]">
      <div className="container flex items-center justify-center">
        <div className="flex flex-wrap items-center justify-center">
          <div className="md:pr-32 pr-0 py-20 md:w-[50%] w-[90%]">
            <div className="flex items-center md:justify-start justify-center">
              <h2 className="text-[--primary-color] text-lg rounded-md font-[700]  border-2 p-2" style={{lineHeight:1.3}}>
                 The Best Services
              </h2>
            </div>
            <div>
              <h3 className="md:text-5xl text-3xl md:text-left text-center font-[600] mt-8 mb-4">
                Our Promise: Delivering Top-Notch Services, Always
              </h3>
            </div>
            <div className="w-[100%] md:w-[80%]  text-[#636571] my-8  text-justify">
              Are you ready to take charge of your health and well-being? At
              OPDSure we are committed to providing top-notch OPD healthcare
              solutions that empower you to lead a healthy and fulfilling life.
              Our team of dedicated experts and cutting-edge technology are here
              to support you every step of the way.
              <br />
              <br />
              <b>
                <span>Contact Us For easy connect with our team, you may write to</span>
                <span className="text-[--primary-color]">&nbsp;support@opdsure.com</span>
              </b>
            </div>

            <div className="flex items-center md:justify-start justify-center">
              <Link to="/contactus">
              <button
                className="mt-auto rounded-md bg-[#3267FF] font-[700]  p-4 w-44   text-[18px] text-white text-center"
               
              >
                Contact Us
              </button>
              </Link>
            </div>
          </div>
          <div className="">
            <img src={servicebg} alt="service" className="h-[600px] md:flex hidden " />
          </div>
          <div className="">
            <img
              src={servicewoman}
              srcSet={`${servicewoman_sm} 420w , ${servicewoman} 1000w`}
              alt="service woman"
              className="h-[300px] flex md:hidden "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
